import * as api from './api';

const staticFilesHOST = process.env.REACT_APP_SERVER_URL;

export async function getCountryDict(){
    const response = await fetch(
        `${staticFilesHOST}/african_countries.geojson`
    );

    try {
        return await response.json();

    } catch(error) {
        console.log('Error happened here!')
        console.error(error)
    }

}

export async function getGeoJsonMap(area){
    let geoJson = {}
    if(area === "africa"){
        const response = await fetch(
            `${staticFilesHOST}/pg_africa.geojson`
        );
        geoJson = await response.json();
    }

    let dict = {};
    geoJson.features.forEach(feature => {
        dict[feature.properties.pg_id] = feature;
    })
    return dict;
}

export async function getCountryDataDict(){

    const response = await fetch(
        `${staticFilesHOST}/african_countries_iso.json`
    );
    let countryJson = await response.json();

    let nameDict = {};
    countryJson.forEach(country => {
        nameDict[country.name] = country;
    })

    const subset = countryJson.map(country => {
         return {
            id:country.id,
            isoCode:country.isoab,
            name:country.name,
            lat:country.caplat,
            long:country.caplong,
        };
    })

    let idDict = {};
    subset.forEach(country => {
        idDict[country.id] = country;
    })
    return {nameDict: nameDict, idDict: idDict};

}

export async function getCountries(){

    const response = await fetch(
        `${staticFilesHOST}/african_countries_iso.json`
    );
    let countryJson = await response.json();

    let dict = {};
    countryJson.forEach(country => {
        dict[country.name] = country;
    })
    return dict;
}

export async function getRuns(dispatch){
    const ret = await api.getRuns(dispatch);
    return ret;
}

export async function getMergedData(){
    return await api.getMergedData();
}

export async function getRunData(runID){
    return await api.getRunData(runID);
}

export async function getCountryPGMData(runID, countryISO){
    return await api.getCountryPGMData(runID, countryISO);
}


