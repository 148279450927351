import React from "react";
import './month-slider.css';
import {useMapDispatch, useMapStore} from "../../state/context";
import * as actions from "../../state/actions";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import {getMonthFromMonthID} from "../../utils/dateUtils";

const MonthSlider = props => {

    const store = useMapStore();
    const dispatch = useMapDispatch();


    const dateParse = (monthID) => {
        let parsed = getMonthFromMonthID(monthID);
        return parsed.replace("/"," ");
    }


    let displayedMonth = dateParse(store.availableRuns[store.selectedRunID].start_date + store.sliderSettings.steps);

    const onChange = (step) => {
        const sliderSettings = store.sliderSettings;
        sliderSettings.steps = step;
        sliderSettings.showMonthSlider = true;

        dispatch(actions.updateMonthSlider(sliderSettings));
    }

    const doUpdate = (step) => {
        const sliderSettings = store.sliderSettings;
        sliderSettings.steps = step;
        dispatch(actions.updateMonthSlider(sliderSettings));
    }

    const steps = store.sliderSettings.steps ? store.sliderSettings.steps : 0;
    const monthTxt = steps ===0 ? "month": "months";

    const headline = store.isChangeMap ? "Prediction step (1-36)" : "Forecast month";
    const stepInfo = store.isChangeMap ? `${steps +1} ${monthTxt} ahead` : displayedMonth;

    return (
        <div className={"sliderContainer"}>
            <div>{headline} </div>
            <Slider
                step={1}
                min={0}
                max={35}
                value={steps}
                dots
                onChange={onChange}
                onAfterChange={doUpdate}
            >
            </Slider>
            <span className={"stepInfo"}>{stepInfo}</span>
        </div>

    )
}

export default MonthSlider;