import React from "react";
import './views-logo.css';


const ViewsLogo = () => {

    return (
            <img alt="Views Logo" className={'viewsLogo'} src={"logo/views_textlogga_och_blob.png"}/>
    )
}

export default ViewsLogo;

