import makeStore from './store'
import {reducer} from './reducer'

const initalState = {}


const [
    MapProvider,
    useMapStore,
    useMapDispatch
] = makeStore(reducer, initalState)

export { MapProvider, useMapStore, useMapDispatch }