import * as actions from "../../state/actions";
import * as mapUtils from "../../utils/mapUtils";
import * as colorUtils from "../../utils/colorUtils";
import {getCountryData} from "../../utils/geoUtils";
import * as dateUtils from "../../utils/dateUtils";
import * as modelUtils from "../../utils/modelUtils";
import * as changeMapUtils from "../../utils/changeMapUtils";

const onCountryClick = async (e, store, feature, dispatch) => {
    const sliderSettings = store.sliderSettings;
    const isCountryMode = false;
    const showChartContainer = true;
    const clickedCountryID = feature.properties.country_id;
    if(store.selectedTypeOfViolence && store.selectedModel) {
        dispatch(actions.setIsCountryMode(isCountryMode, store.mapSettings, showChartContainer, clickedCountryID));
    }
}

function onMouseOverCountry(e, fillOpacity){
    let layer = e.target;

    layer.setStyle({
        weight: 3,
        color: '#666',
        dashArray: '',
        fillOpacity: fillOpacity
    });

    layer.openPopup()
}

function onMouseOutCountry(e, isCountryMode, clickedCountry, fillOpacity){
    let layer = e.target;
    let isSelectedCountry = clickedCountry ===layer.feature.properties.country_id;

    let isSelectedWeight = (isCountryMode && isSelectedCountry)?3:1;

    layer.setStyle({
        weight: isSelectedWeight,
        color: '#666',
        dashArray: '',
        fillOpacity: fillOpacity
    });

    layer.closePopup()
}

const onAreaClick = async (e, store, feature, dispatch) => {

    let chartSettings = store.chartSettings;
    chartSettings.context = "pgm";
    if(store.selectedTypeOfViolence && store.selectedModel) {
        dispatch(actions.updateChartSettingsAndArea(chartSettings, feature.properties.pg_id));
    }

}

function onMouseOverArea(e, fillOpacity){
    let layer = e.target;

    layer.setStyle({
        weight: 3,
        color: '#666',
        dashArray: '',
        fillOpacity: 0
    });

    layer.openPopup()
}

function onMouseOutArea(e, fillOpacity){
    let layer = e.target;

    layer.setStyle({
        weight: 1,
        color: '#666',
        dashArray: '',
        fillOpacity: fillOpacity
    });

    layer.closePopup()
}


function getPGMTovData(store, pgId) {
    let pgmData = store.pgmDataStore[store.selectedRunID]
    let countryData = pgmData[store.areaDict[store.clickedCountry].isoCode];
    let pgTovData = countryData.forecasts[store.selectedTypeOfViolence.value][pgId]

    return pgTovData;
}

function getPGMModelValue(store, runId, feature, tov, selectedModel, currentMonthID){

    // leta reda på var data finns.. och id för området
    let modelValue = 0;
    if('pg_id' in feature.properties && store.pgmDataStore[store.selectedRunID]){
        let tovData =  getPGMTovData(store, feature.properties.pg_id)
        let monthData = tovData[currentMonthID];
        if(!monthData){
            modelValue = 0;
            console.log("Monthly data for monthId: " + currentMonthID + "in PGID: " + feature.properties.pg_id + " is missing. Start-date for run is: " + store.availableRuns[runId].start_date)
        }
        else {
            modelValue = monthData[selectedModel.value]
        }

    }

    return modelValue;
}

const generateChangeMapPopupMarkup = (countryName, countryColor, risk, changeMapSettings) => {

    let rel1 = changeMapSettings.runID1.replace("r_","");
    let rel2 = changeMapSettings.runID2.replace("r_","");

    const color = colorUtils.pickTextColorBasedOnBgColorAdvanced(countryColor);

    let prettyDiff = (risk*100).toFixed(2);

    return `<div class="areaPopup" style="z-index: 7000; width: 260px;">
                <div>
                    <h1>${countryName}</h1>
                    <span style="font-size: 14px;"><b>Model:</b> ${changeMapSettings.model.label}</b></span>
                </div>
                
                <div style="display:block; font-size: 14px; margin-top: 20px;">
                    <b>Compared data releases:</b><br/>
                    ${rel1} - ${rel2}
                </div>
                
                <div style="text-align: left; display: block; margin-top: 20px; margin-bottom: 30px; font-size: 14px; width: 100%; overflow: auto;">
                    <b>Change in predicted probability:</b> <br/>
                    <div style="background-color: ${countryColor}; color: ${color}; float: left; padding: 6px 6px 6px 8px;">${prettyDiff} pp</div> 
                </div>
                
           </div>`;

}

const generatePopupMarkup = (areaName, modelLabel, countryColor, risk, mode) => {

    let probabilityDescription = "Predicted probability of &ge;25 BRDs per month";
    let userInstruction = "Click on the country for more information and access to the sub-national forecasts";

    if(mode==="pgm"){
        probabilityDescription = "Predicted probability of &ge;1 BRD per month";
        userInstruction = "Click on an area for more information";
    }

    const fontColor = colorUtils.pickTextColorBasedOnBgColorAdvanced(countryColor);

    return `<div style="width: 260px;">
                <div>
                    <h1>${areaName}</h1>
                    <span style="font-size: 14px;"><b>Model:</b> ${modelLabel}</b></span>
                </div>
                
                <div style="text-align: left; display: block; margin-top: 20px; font-size: 18px; font-weight: 800; width: 100%; overflow: auto;">
                    <div style="background-color: ${countryColor}; color: ${fontColor}; float: left; padding: 6px; padding-left: 8px;">Risk: ${risk.toFixed(2)} %</div> 
                </div>
                
                <div style="display:block; font-size: 14px; font-style: italic; margin-top: 20px;">${probabilityDescription}</div>
                
                <div style="font-size: 14px; margin-top: 14px;">
                    ${userInstruction}
                </div>

           </div>`;

}

// Get the popup contents of a feature (country) when in CM mode
function getPopupContents(feature, store){

    const countryId = feature.properties.country_id ? feature.properties.country_id : feature.properties.geo_country_id
    const countryData = getCountryData(store, countryId);
    const predictionMonthID = dateUtils.getPredictionMonthID(store, store.selectedRunID)

    if(store.isPredictionsMap === true) {
        if (store.selectedModel?.value) {
            const modelValue = modelUtils.getModelValue(store, store.selectedRunID, feature, store.selectedTypeOfViolence.value, store.selectedModel, predictionMonthID);
            const countryColor = mapUtils.getCountryColor(modelValue);

            const risk = modelValue*100;

            return generatePopupMarkup(countryData.name, store.selectedModel.label, countryColor, risk, "cm");

        } else {
            return `<h1>${countryData.name}</h1>Select settings in the menu and click “Fetch predictions” to see conflict predictions`;
        }
    }

}

// Get the popup contents of a feature (PrioGrid area) when in PGM mode
function getPGMPopupContents(feature, store){

    if(store.isPredictionsMap === true) {
        if (store.selectedModel) {

            const countryIso = store.areaDict[feature.properties.geo_country_id].isoCode;
            let countryPgmData = store.pgmDataStore[store.selectedRunID][countryIso]

            //get tovdata per month
            let tovData = countryPgmData.forecasts[store.selectedTypeOfViolence.value][feature.properties.pg_id];
            let currentMonthID = dateUtils.getPredictionMonthID(store, store.selectedRunID);
            let monthData = tovData[currentMonthID];
            let modelValue = 0;
            if(monthData){
                modelValue = monthData[store.selectedModel?.value]
            }
            let areaColor = "#ffffff";
            let risk = 0;
            if(modelValue){
                areaColor = mapUtils.getCountryColor(modelValue);
                risk = modelValue*100;
            }

            const areaName = `PRIO-GRID ID: ${feature.properties.pg_id}`;

            return generatePopupMarkup(areaName, store.selectedModel.label, areaColor, risk, "pgm");

        } else {
            return `Select model to see predictions.`;
        }
    }
}

// Get the popup contents of a feature (country) when  changeMap is selected
function getChangeMapPopUpContents(feature, store){
    if(store.changeMapSettings.tov !== undefined && store.changeMapSettings.model !== undefined) {
        let countryId = feature.properties.country_id ? feature.properties.country_id : feature.properties.geo_country_id
        let countryName = store.areaDict[countryId].name;

        const valueDiff = changeMapUtils.getValueDiff(store, feature);
        const countryColor = changeMapUtils.getCountryColor(valueDiff);

        return generateChangeMapPopupMarkup(countryName, countryColor, valueDiff, store.changeMapSettings)
    }
    else {
        return `Please specify what data you wish to compare in the menu above.<br/><br/>The generated map will show how the specified forecasts changed (in percentage points, pp) between the two points in time. <br/><br/>Red colors indicate an increase in the predicted probability of conflict between the two data releases; blue colors a decrease; and white no change at all. <br/><br/>To learn more about how to interpret these maps, please consult the monthly forecast reports under “Resources” above.”`;
    }
}


// Get the color and data for each feature (country or area) of the GeoJSON data
export function getFeatureStyle(feature, store){

    const isSelectedCountry = feature.properties?.country_id === store.clickedCountry;
    const fillOpacity = store.mapSettings.opacity;
    let countryColor = "#ffffff"; // Default color
    let modelValue = 0;  // Default value if no value can be calculated for some reason

    let countryData = {}
    countryData.countryColor = countryColor;
    countryData.startMonthID = dateUtils.getPredictionMonthID(store, store.selectedRunID);
    countryData.modelValue = modelValue;
    countryData.selectedTypeOfViolence = store.selectedTypeOfViolence;
    countryData.runID = store.selectedRunID;
    countryData.selectedModel = store.selectedModel;

    if(store.isPredictionsMap === true){
        if(!store.selectedModel || !store.selectedModel?.value){
            countryColor="#ffffff";
        }
        else{
            if(countryData.startMonthID && store.selectedTypeOfViolence && store.selectedRunID) {
                if(store.predictionsMapMode === 'cm') {
                    modelValue = modelUtils.getModelValue(store, store.selectedRunID, feature, store.selectedTypeOfViolence.value, store.selectedModel, countryData.startMonthID);
                    countryColor = mapUtils.getCountryColor(modelValue);
                }
                else if(store.predictionsMapMode==='pgm'){
                    modelValue = getPGMModelValue(store, store.selectedRunID, feature, store.selectedTypeOfViolence.value, store.selectedModel, countryData.startMonthID);
                    if(store.sliderSettings.showMonthSlider){
                        countryColor = mapUtils.getCountryColor(modelValue);
                    }
                }
            }
        }
    }
    else if(store.isChangeMap && changeMapUtils.canRender(store)){

        const valueDiff = changeMapUtils.getValueDiff(store, feature);
        countryColor = changeMapUtils.getCountryColor(valueDiff);

    }

    return {
        fillColor: countryColor,
        fillOpacity: fillOpacity,
        weight: isSelectedCountry ? 3 : 1,                          // border weight
        color: '#666',                                              // border color on map
        dashArray: store.predictionsMapMode==='pgm' ? '1':'3',      // type of border
        opacity: store.predictionsMapMode==='pgm' ? 0.1 : 1,        // border opacity

    };
}

export function setupFeatureActions(feature, layer, store,  dispatch){
    let monthId = store.availableRuns[store.selectedRunID].start_date;
    console.log(monthId)
    if(store.isPredictionsMap === true && store.predictionsMapMode === 'cm') {
        layer.bindPopup(getPopupContents(feature, store));
        layer.on({
            click: (e) => onCountryClick(e, store, feature, dispatch),
            mouseover: (e) => onMouseOverCountry(e,store.mapSettings.opacity),
            mouseout: (e) => onMouseOutCountry(e,store.isCountryMode,store.clickedCountry,store.mapSettings.opacity)
        });

    }
    else if(store.isPredictionsMap === true && store.predictionsMapMode === 'pgm') {
        layer.on({
            click: (e) => onAreaClick(e, store, feature, dispatch),
            mouseover: (e) => onMouseOverArea(e, store.mapSettings.opacity),
            mouseout: (e) => onMouseOutArea(e, store.mapSettings.opacity)
        });
        const popup = getPGMPopupContents(feature, store)
        layer.bindPopup(popup);
    }
    else if(store.isChangeMap === true){
        layer.on({
            mouseover: (e) => onMouseOverArea(e, store.mapSettings.opacity),
            mouseout: (e) => onMouseOutArea(e, store.mapSettings.opacity)
        });
        if(store.availableRuns[store.changeMapSettings.runID1].cmData && store.availableRuns[store.changeMapSettings.runID2].cmData){
            const popup = getChangeMapPopUpContents(feature, store)
            layer.bindPopup(popup);
        }

    }
}