import React from "react";
import 'react-tabs/style/react-tabs.css';
import PredictionPanel from "../prediction-panel/predictionPanel";
import "./sidepanel.css"

const SidePanel = () => {
    return (
        <div className={"sidePanel"}>
            <PredictionPanel/>
        </div>
    )

}

export default SidePanel;