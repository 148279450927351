import * as heatMaps from "../style/heatmaps";
import * as modelUtils from "./modelUtils";

export const canRender = store => {
    return store.changeMapSettings.tov !== undefined
        && store.changeMapSettings.model !== undefined
        && store.availableRuns[store.changeMapSettings.runID1].cmData
        && store.availableRuns[store.changeMapSettings.runID2].cmData;
}

export const getValueDiff = (store, feature) => {

    const steps = store.sliderSettings.steps;

    const predictionMonth1 = store.availableRuns[store.changeMapSettings.runID1].start_date + steps -1;
    const predictionMonth2 = store.availableRuns[store.changeMapSettings.runID2].start_date + steps -1;

    const modelValue1 = modelUtils.getModelValue(store, store.changeMapSettings.runID1, feature, store.changeMapSettings.tov.value, store.changeMapSettings.model, predictionMonth1);
    const modelValue2 = modelUtils.getModelValue(store, store.changeMapSettings.runID2, feature, store.changeMapSettings.tov.value, store.changeMapSettings.model, predictionMonth2);

    let valueDiff = modelValue1 - modelValue2;
    console.log("Value diff for " + feature.properties?.country_id + " is: " + valueDiff)
    return valueDiff;
}

const seismicHeatMap = heatMaps.getSeismicRanges();
export function getCountryColor(value) {
    let color = "";

    if(value < seismicHeatMap[0].minValue){
        color = seismicHeatMap[0].hex;
    }
    else if(value > seismicHeatMap[255].maxValue){
        color = seismicHeatMap[255].hex;
    }
    else {
        for (let el of seismicHeatMap) {
            if (value < el.maxValue) {
                break;
            }
            color = el.hex;
        }
    }
    if(color===""){ color = "#FFFFFF";}

    return color;
}