export const getModelDescription = (techmodel, descriptions) => {
    let description = techmodel;
    if(descriptions.length>0){
        let lmodel = techmodel.toLowerCase();
        if(lmodel==="onset24_25_all"){lmodel="onset_24_25_all";}
        let info = descriptions.find(element => lmodel.includes(element["id"]));
        if(info && info.name){ description = info.name; }
    }
    return description;
}

export const getModelValue = (store, runId, feature, tov, selectedModel, currentMonthID) => {
    const cmData = store.availableRuns[runId].cmData
    const countryId = feature.properties.country_id ? feature.properties.country_id : feature.properties.geo_country_id
    const countryIso = store.areaDict[countryId]?.isoCode;

    const tovData = cmData[countryIso]?.forecasts[tov];

    return tovData[currentMonthID][selectedModel.value];
}

