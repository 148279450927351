import React from "react";
import '../selectors.css';
import {Menu, MenuButton,  MenuItem} from "@szhsin/react-menu";
import {ArrowRightIcon, ChevronDownIcon} from "@primer/octicons-react";
const TOVSelector = props => {

    const handleFileClick = e => {
        let option = e.value;
        let tov = props.typesOfViolence.find( tov => tov.value === option.value)
        props.setTypeOfViolence(tov);
        e.stopPropagation = true;
    }

    const getDisabled = (option) => {
        return props.isDisabled || option.value === props.selectedTypeOfViolence?.value;
    }

    let orderedIds = props.typesOfViolence.sort().reverse();
    const header = props.selectedTypeOfViolence ? props.selectedTypeOfViolence.label : "Type of violence";

    return (
        <div className={'menuItem'}>
            <div className={'selector-description'}>
                {props.headLine}<ArrowRightIcon size={12}/>
            </div>
            <div className={'selector-container'}>
                <Menu menuButton={<MenuButton className={'selector_button'}><div className={'selectorTextContainer'}>{header}</div> | <ChevronDownIcon color="#626262" size={20} /></MenuButton>}>
                    {orderedIds.map((option, i) => <MenuItem value={option} onClick={handleFileClick}  disabled={getDisabled(option)} key={i}>{option.label} </MenuItem>)}
                </Menu>

            </div>
        </div>
    );

}

export default TOVSelector;

