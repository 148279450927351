import ChangeMapTab from "../changemap-tab/changeMapTab";
import React from "react";

import CMTab from "../cm-tab/cmTab";
import PGMTab from "../pgm-tab/pgmTab";

import './prediction-panel.css';
import {useMapDispatch, useMapStore} from "../../state/context";
import * as actions from "../../state/actions";
import * as viewsData from "../../backend/data";
import * as mapUtils from "../../utils/mapUtils";

const PredictionPanel = () => {

    const store = useMapStore();
    const dispatch = useMapDispatch();

    const setPredictionsTab = () => {
        let sliderSettings = store.sliderSettings;
        sliderSettings.showMonthSlider = false;
        sliderSettings.steps = 0;
        if(store.isChangeMap){
            dispatch(actions.updateIsPredictionMap(true, sliderSettings));
        }


    }
    const setChangeMapTab = async () => {
        let sliderSettings = store.sliderSettings;
        sliderSettings.showMonthSlider = false;

        const countryGeo = await viewsData.getCountryDict();        // countries.geojson
        let geoJsonData = mapUtils.createFeatureCollection(Object.values(countryGeo));

        let mapSettings = store.mapSettings;
        mapSettings.showOpacitySlider = true;
        mapSettings.opacity = 0;
        mapSettings.zoom = 4;
        mapSettings.mapCenterPosition = [0.105,28]; // africa
        mapSettings.keyMAP = 22;

        const isCountryMode = true;
        const predictionsMapMode = "cm";


        dispatch(actions.updateIsChangeMap(true, sliderSettings, geoJsonData, isCountryMode, predictionsMapMode));

    }

    return (

        <div className={'predictionPanelContainer'}>

            <div className={'menuItem'}>
                <div className={'selector-description'}>Map view:</div>
                <div className={'selector-container'}>
                    <button className={`selectorButton ${!store.isChangeMap ? "active": "inActive"}`} onClick={setPredictionsTab}>Forecasts</button>
                    <button className={`selectorButton ${store.isChangeMap ? "active": "inActive"}`} onClick={setChangeMapTab}>Change map</button>
                </div>
            </div>

            <div className={'menuItem'}>
                <div className={'selector-description'}></div>
                <div className={"divider"}></div>
            </div>


            <div className={"data-selector-container"}>

            {store.predictionsMapMode === "cm" && store.isChangeMap === false &&
                    <CMTab />
                }

                {store.predictionsMapMode === "pgm" && store.isChangeMap === false &&
                    <PGMTab/>
                }

                {store.isChangeMap === true &&
                    <ChangeMapTab/>
                }

            </div>

        </div>

    )
}

export default PredictionPanel;


