import * as predictionUtils from "./predictionUtils";
import * as dateUtils from "./dateUtils";


// get available models för a specific month
export const getSelectableModels = store => {
    let models = store.selectableModels;
    let startMonthID = dateUtils.getPredictionMonthID(store, store.selectedRunID);
    if(store.clickedCountry) {
        let predictions = predictionUtils.getActiveCMPredictions(store);
        if(predictions){
            models = Object.keys(predictions[startMonthID])
        }
    }
    return models;
}

export const getSelectedModel = store => {
    let cmModels = getSelectableModels(store);
    if(cmModels && cmModels.includes(store.selectedModel?.value)){
        return store.selectedModel;
    }
    else{
        const errorObj = {
            heading: "Model missing",
            message: "Previously selected model doesn't exist at this level. Please select another model."
        }

        // should ONLY be dispatched when coming from pgm
        if(store.selectedModel !== ""){
            console.log("show error!! Model not selected")
            //dispatch(actions.showError(errorObj));
        }

    }

    return undefined; // or whatever it should be when empty
}