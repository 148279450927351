import * as heatMaps from '../style/heatmaps';

export function createFeatureCollection(features){
    return {
        "type": "FeatureCollection",
        "crs": {
            "type": "name",
            "properties": {
                "name": "urn:ogc:def:crs:OGC:1.3:CRS84"
            },
        },
        "features": features
    };
}

const heatMap = heatMaps.getShiftedRainbowRanges();
export function getCountryColor(value) {
    let color = "";
    var previous = {};
    for (let el of heatMap) {
        if (value > el.maxValue) {
            color = previous.hex;
            break;
        }
        previous = el;
    }

    if(color===""){
        color=heatMap[255].hex;
    }
    return color;
}



export const setMapSettings = (store, clickedCountry) => {
    const mapSettings = Object.assign({}, store.mapSettings);

    mapSettings.mapCenterPosition = [store.areaDict[clickedCountry].lat, store.areaDict[clickedCountry].long];
    mapSettings.zoom = 4;
    mapSettings.showOpacitySlider = true;
    mapSettings.opacity = 0.5;

    return mapSettings;
}