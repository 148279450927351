
import {african_countries} from '../backend/africa_country_ids';

export function mergeDataWithGeoJson(data, key, columns, geoJson){
    const geoJsonCopy = JSON.parse(JSON.stringify(geoJson));
    data.forEach( row => {
        columns.forEach(column => {
            if(row[column] !== null){
                geoJsonCopy[row[key]].properties[column] = row[column];
                geoJsonCopy[row[key]].properties['name'] = row[column];
                geoJsonCopy[row[key]].properties["fillColor"] = "#ffffff"
            }
        })
    })
    console.log(geoJsonCopy);
    return Object.values(geoJsonCopy);
    // return geoJsonCopy;
}


/*
* These functions merges column data with geoJSON based on columns fetched from API.
* Resulting data is displayed in map
* */
export function mergeCountryData(data, geoJson, columns){
    const res = mergeDataWithGeoJson(data, "country_id", columns, geoJson);
    return res;
}

export function mergeGridData(data, geoJson, columns){
    return mergeDataWithGeoJson(data, "pg_id", columns, geoJson);
}

export function generateCountryDict(){
    const dict = {};
    african_countries.forEach(country => {
        dict[country.id]=country.name;
    })
    return dict
}

export function getCountryData(store, countryId){
    return store.areaDict[countryId];
}

