import React from "react";
import './style.css';

const RainbowGradient = () => {

    return (
        <div className={'gradientContainer'}>

            <div className="container">
                <div className="metaText">Probability</div>
                <div className={'innerContainer'}>
                    <div className="rainbow">&nbsp;</div>
                    <div className="rainbow_scale">
                        <div className="h99">- 99%</div>
                        <div className="h97">&nbsp;</div>
                        <div className="h95">- 95%</div>
                        <div className="h90">- 90%</div>
                        <div className="h80">- 80%</div>
                        <div className="h60">- 60%</div>
                        <div className="h40">- 40%</div>
                        <div className="h20">- 20%</div>
                        <div className="h10">- 10%</div>
                        <div className="h05">- 5%</div>
                        <div className="h02">- 2%</div>
                        <div className="h01">- 1%</div>
                        <div className="h005">- 0.5%</div>
                        <div className="h002">- 0.2%</div>
                    </div>
                </div>

            </div>
        </div>
    );

}

export default RainbowGradient;