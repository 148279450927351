import React from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Text, ResponsiveContainer } from 'recharts';
import {useMapDispatch, useMapStore} from "../../state/context"
import './chart-container.css';
import * as actions from "../../state/actions";
import * as predictionUtils from "../../utils/predictionUtils";
import {getMonthFromMonthID} from "../../utils/dateUtils";
import CMButton from "../cm-button/cmButton";
import Closer from "../closer/closer";
import Draggable from 'react-draggable';
import { CSVLink } from "react-csv";
import {DownloadIcon, FileIcon, GraphIcon} from '@primer/octicons-react'


const ChartContainer = props => {

    const store = useMapStore();
    const dispatch = useMapDispatch();

    const countryData = store.areaDict[store.clickedCountry];
    const country = countryData.name;

    let chartData = [];

    let csvFileName = `${country}_${store.selectedTypeOfViolence.value}_${store.selectedModel.value}.csv`

    let headers = [
        { label: "Date", key: "month" },
        { label: "Predicted probability", key: "probability" },
        { label: "Unit", key: "unit" }
    ];

    const getPoint = (store, predictions, month) => {
        const point = {month: getMonthFromMonthID(month)}
        const modelValues = predictions[month];
        const value = modelValues[store.selectedModel.value]
        point.probability = Math.round(value*100);
        point.unit="%";
        return point;
    }

    const getChartData = store => {

        const currentMonthID = store.availableRuns[store.selectedRunID].start_date;
        const maxMonth = currentMonthID + 36;

        let predictions = {};
        let chartData = [];

        if(store.predictionsMapMode === "cm") {
            predictions = predictionUtils.getActiveCMPredictions(store);
            if(predictions) {
                for (const month in predictions) {
                    if (month < maxMonth && month > currentMonthID -1) {
                        chartData.push(getPoint(store, predictions, month))
                    }
                }
            }
        }
        else if(store.predictionsMapMode === "pgm"){

            csvFileName = `PRIO_GRID_ID_${store.clickedAreaID}_${store.selectedTypeOfViolence.value}_${store.selectedModel.value}.csv`

            let predictions = predictionUtils.getActiveCMPredictions(store)
            if(store.clickedAreaID) {
                predictions = predictionUtils.getActiveAreaPGMPredictions(store);
            }
            if(predictions){
                let months = Object.keys(predictions);
                for(const monthStr of months){
                    let month = parseInt(monthStr, 10)
                    if( month < maxMonth && month > currentMonthID -1 ){
                        chartData.push(getPoint(store, predictions, month))

                    }
                }
            }
        }

        return chartData;

    }

    if(store.selectedTypeOfViolence){
        chartData = getChartData(store);
    }


    function closeModelPanel(){
        dispatch(actions.setShowChartContainer(false));
    }

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p className="label">{`Date: ${label}`}</p>
                    <p className="desc">{`Probability : ${payload[0].value} %`}</p>
                    {store.predictionsMapMode === "pgm" &&
                    <p className="desc">{`Prio Grid ID : ${store.clickedAreaID} %`}</p>
                    }
                </div>
            );
        }

        return null;
    };


    const description = store.predictionsMapMode === "cm" ? "Predicted probability of at least 25 battle-related deaths per type of violence, country and month" : "Predicted probability of at least one battle-related death per type of violence, PRIO-GRID cell and month";

    const header = store.predictionsMapMode === "cm" ? country: `PRIO-GRID ID: ${store.clickedAreaID}`



     return (
         <React.Fragment>
            {store.selectedTypeOfViolence && store.isPredictionsMap===true &&

            <Draggable bounds="parent" handle="#windowTitle">

                <div className={'chartDragger'}>
                    <div className={'chartWrapper'}>
                        <div id={'windowTitle'} title={"Click to drag and move"}>
                            <span className={"countryHeader"}>{header}</span>
                            <CSVLink
                                data={chartData}
                                headers={headers}
                                filename={csvFileName}
                                className="csv_btn"
                                target="_blank"
                                title={'Click to download data'}
                            >
                                <FileIcon size='small'/> <DownloadIcon size='small'/> Download csv
                            </CSVLink>
                            <Closer onClose={(e)=>{closeModelPanel(e)}}>x</Closer>
                        </div>
                        <div className={'legend'}>

                            <CMButton/>
                            <div className={'metaDescriptions'}>
                                <b>Current model:</b>&nbsp;{store.selectedModel.label} &nbsp;&nbsp;&nbsp;   <b>Type of violence:</b>&nbsp;{store.selectedTypeOfViolence.label}<br/>
                                <b>Current model ID:</b>&nbsp;{store.selectedModel.value} &nbsp;&nbsp;&nbsp;   <b>Data release:</b>&nbsp;{store.selectedRunID}<br/>
                            </div>
                        </div>

                        <ResponsiveContainer width="100%" height="100%">
                        <div className={'lineChart'}>
                            <div style={{width:"100%", textAlign: "center", paddingTop: "6px", fontSize: "14px"}}>{description}</div>
                                <LineChart data={chartData}
                                           width={750} height={250}
                                           margin={{top: 10, right: 0, left: 20, bottom: 5}}
                                           label={(entry) => `${entry}%`}>
                                    <CartesianGrid strokeDasharray="3 3"/>
                                    <XAxis dataKey="month" tick={{fontSize: 10}}/>

                                    <YAxis label={{ value: 'Predicted probability (%)', angle: -90, position: 'insideBottomLeft' }} tick={{fontSize: 10}} yAxisId="left" unit={'%'} />
                                    <YAxis yAxisId="right" orientation="right" />
                                    <Tooltip content={<CustomTooltip />} />
                                    <Text scaleToFit={true} />
                                    <Line yAxisId="left" type="monotone" dataKey={'probability'} stroke="#8884d8" activeDot={{r: 8}}/>
                                </LineChart>

                        </div>
                        </ResponsiveContainer>
                    </div>
                </div>

            </Draggable>

            }

         </React.Fragment>
    )
}

export default ChartContainer;