import React from "react";
import './style.css';

const SeismicGradient = () => {

    return (
        <div className={'seismicGradientContainer'}>
            <div className="container">
                <div className="metaText">Change in <br/>probability (pp)</div>
                <div className={'sinnerContainer'}>
                    <div className="seismic"></div>
                    <div className="seismic_scale">
                        <div className="h99">100 pp</div>
                        <div className="h97">75 pp</div>
                        <div className="h95">50 pp</div>
                        <div className="h90">25 pp</div>
                        <div className="h80">0 pp</div>
                        <div className="h60">-25 pp</div>
                        <div className="h40">-50 pp</div>
                        <div className="h20">-75 pp</div>
                        <div className="h10">-100 pp</div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default SeismicGradient;