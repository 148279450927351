import * as viewsData from "../backend/data";

export const verifyAndUpdateRunData = async (availableRuns, runID) => {
    if( !availableRuns[runID].cmData || !availableRuns[runID].codeBook ) {
        const runData = await viewsData.getRunData(runID);
        availableRuns[runID] = generateRunObject(runData);
    }
    return availableRuns;
}


// Creates a data object for a specific "run" of predictions
export const generateRunObject = (run) => {

    let models = undefined;
    let codeBook = undefined;
    let start_date = undefined;

    if(run.cmData !== undefined && run.cmData.length>0){
        models = run.cmData[0].models;
        let countrydict = {};
        run.cmData.forEach(country => {
            countrydict[country.iso] = country
        })
        run.cmData = countrydict;
    }
    if(run.codeBook !== undefined && Object.keys(run.codeBook).length>0){
        codeBook = run.codeBook;
    }

    if(run.start_date !== undefined){
        start_date = run.start_date;
    }

    return {runId: run.runId, start_date: start_date, cmData: run.cmData, models: models, codeBook: codeBook};
}

export const generateAvailableRuns = runs => {
    let availableRuns = {};
    runs.forEach( run => {
        availableRuns[run.runId] = generateRunObject(run);
    })
    return availableRuns;
}
