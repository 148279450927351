import React from "react";
import "./spinner.css"
import ClockLoader from "react-spinners/ClockLoader";
import {css} from "@emotion/react";

const Spinner = () => {

    const spinnerOverride = css`
      display: block !important;
      margin: 0 auto;
      border-color: red;
    `;

    return (
        <React.Fragment>
            <div id={"spinnerBG"}>
                <div id={"spinner"}>
                    <ClockLoader color="#ffffff" loading={true} css={spinnerOverride} size='130px' />
                    <br/>
                    <br/>
                    <div className={"blockedDiv"}>Fetching data.....</div>
                </div>
            </div>
        </React.Fragment>
    );

}

export default Spinner;

