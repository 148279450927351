import React from "react";
import * as predictionUtils from '../../utils/predictionUtils';
import 'react-tabs/style/react-tabs.css';
import "react-toggle/style.css"

import ModelSelector from '../selectors/model-selector/modelSelector';
import TOVSelector from '../selectors/tov-selector/tovSelector';
import ForecastSelector from '../selectors/forecast-selector/forecastSelector';

import * as actions from "../../state/actions";
import {useMapDispatch, useMapStore} from "../../state/context";
import * as mapUtils from "../../utils/mapUtils";
import * as viewsData from "../../backend/data";
import {generateRunObject} from "../../utils/dataUtils";

import {getSelectedModel, getSelectableModels} from "../../utils/cmUtils";


const CMTab = () => {

    const store = useMapStore();
    const dispatch = useMapDispatch();
    const hasTOV = store.selectedTypeOfViolence !== undefined;
    const modelDescriptions = store.availableRuns[store.selectedRunID].codeBook[store.predictionsMapMode]


    const setRunID = async runId => {

        if(store.availableRuns[runId].cmData === undefined){

            dispatch(actions.setSpinner(true))

            // Get complete run data
            const runData = await viewsData.getRunData(runId)
            const runObject = generateRunObject(runData)
            store.availableRuns[runId] = runObject

            // Update start month.
            // Todo Refactor entire handling of prediction starting month now that it's added to the run object
            store.chartSettings.startMonthID = runObject.start_date
            store.chartSettings.predictionMonthID = runObject.start_date
            dispatch(actions.updateRunData(runId, store.availableRuns, store.chartSettings, store.sliderSettings))
        }
        else{
            let newRun = store.availableRuns[runId];
            // Update start month.
            // Todo Refactor entire handling of prediction starting month now that it's added to the run object
            store.chartSettings.startMonthID = newRun.start_date
            store.chartSettings.predictionMonthID = newRun.start_date

            dispatch(actions.updateSelectedRunID(runId, store.chartSettings, store.sliderSettings));
        }
    }

    const filterModels = tov => {
        return store.availableRuns[store.selectedRunID].models[tov];
    }

    const setTypeOfViolence = tov => {
        if(tov!==store.selectedTypeOfViolence){
            const selectableModels = filterModels(tov.value);
            dispatch(actions.updateTypeOfViolence(tov, selectableModels));
        }
    }

    const setModel = model => {
        if(model.value!==store.selectedModel?.value){
            dispatch(actions.updatePredictionModel(model));
        }
    }

    const submit = async () => {
        if(store.selectedModel){
            let geoData = mapUtils.createFeatureCollection(Object.values(store.countryGeo));
            let mapSettings = store.mapSettings;
            mapSettings.showOpacitySlider = true;
            mapSettings.opacity = 0.5;

            store.sliderSettings.showMonthSlider = true;

            const errorObject = undefined;
            dispatch(actions.updateMap(store.availableRuns, geoData, mapSettings, store.isCountryMode, store.clickedCountry, store.predictionsMapMode, store.pgmDataStore, store.chartSettings, errorObject, store.showChartContainer, store.sliderSettings, store.selectedModel));
        }
    }


    //const className = "submit";
    let className = "submit";
    if(!store.selectedModel || !store.selectedModel.value){
        className = "submit_inactive";
    }

    let selectableModels = getSelectableModels(store);
    let selectedModel = getSelectedModel(store);

    return (
            <React.Fragment>

                <ForecastSelector
                     runIds ={store.runIDs}
                     selectedRunID={store.selectedRunID}
                     setRunID={setRunID}
                     headLine="Data release:"
                />


                <TOVSelector
                    selectedTypeOfViolence={store.selectedTypeOfViolence}
                    typesOfViolence={store.typesOfViolence}
                    setTypeOfViolence={setTypeOfViolence}
                    headLine="Type of violence:"
                />

                <ModelSelector
                    models={selectableModels}
                    descriptions={modelDescriptions}
                    isDisabled={!hasTOV}
                    selectedModel={selectedModel}
                    setModel={setModel}
                    headLine="Prediction model:"
                />

                <div className={'menuItem'}>
                    <div className={'selector-description'}></div>
                    <div className={'selector-container'}>
                        <button className={className} onClick={submit}>Fetch predictions</button>
                    </div>
                </div>

            </React.Fragment>

    )

}

export default CMTab;