export const getActiveCountryPGMPredictions = store => {
    const isoCode = store.areaDict[store.clickedCountry].isoCode;
    return store.pgmDataStore[store.selectedRunID]?.[isoCode];
}

export const getActiveAreaPGMPredictions = (store) => {
    const countryPrediction = getActiveCountryPGMPredictions(store);
    return countryPrediction?.forecasts[store.selectedTypeOfViolence.value]?.[store.clickedAreaID];
}

export const getActiveCMPredictions = store => {
    let predictions = undefined;

    const isoCode = store.areaDict[store.clickedCountry].isoCode;
    let cmData = store.availableRuns[store.selectedRunID]?.cmData;

    if(cmData && cmData[isoCode] && cmData[isoCode].forecasts){
        predictions =  cmData[isoCode].forecasts[store.selectedTypeOfViolence.value]
    }

    return predictions;
}
