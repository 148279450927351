/*
 * action types
 */


export const SHOW_ERROR = 'SHOW_ERROR';
export const HIDE_ERROR = 'HIDE_ERROR';
export const UPDATE_MAP = 'UPDATE_MAP';
export const UPDATE_IS_PREDICTION_MAP = 'UPDATE_IS_PREDICTION_MAP';
export const UPDATE_SET_PREDICTION_MAP_MODE = 'UPDATE_SET_PREDICTION_MAP_MODE';
export const UPDATE_IS_CHANGE_MAP = 'UPDATE_IS_CHANGE_MAP';
export const UPDATE_CHANGE_MAP_SETTINGS = 'UPDATE_CHANGE_MAP_SETTINGS';

export const SET_INITIAL_STORE = 'SET_INITIAL_STORE';

export const UPDATE_RUN_ID = 'UPDATE_RUN_ID';
export const UPDATE_RUN_DATA = 'UPDATE_RUN_DATA';

export const UPDATE_TYPE_OF_VIOLENCE = 'UPDATE_TYPE_OF_VIOLENCE';
export const UPDATE_SELECTED_AREA = 'UPDATE_SELECTED_AREA';
export const UPDATE_MAP_SETTINGS = 'UPDATE_MAP_SETTINGS';
export const UPDATE_CHART_SETTINGS = 'UPDATE_CHART_SETTINGS';
export const UPDATE_CHART_SETTINGS_AND_AREA = 'UPDATE_CHART_SETTINGS_AND_AREA';

export const SET_IS_COUNTRY_MODE = 'SET_IS_COUNTRY_MODE';
export const SET_SHOW_MODEL_DESCRIPTOR = 'SET_SHOW_MODEL_DESCRIPTOR';
export const SET_SHOW_CHART_CONTAINER = 'SET_SHOW_CHART_CONTAINER';
export const UPDATE_MONTH_SLIDER = 'UPDATE_MONTH_SLIDER';


export const UPDATE_COUNTRY_PREDICTION_COLUMNS = 'UPDATE_COUNTRY_PREDICTION_COLUMNS';
export const UPDATE_PREDICTION_COUNTRIES = 'UPDATE_PREDICTION_COUNTRIES';
export const UPDATE_PREDICTION_MODEL = 'UPDATE_PREDICTION_MODEL';
export const UPDATE_PREDICTION_VERSION = 'UPDATE_PREDICTION_VERSION';

export const UPDATE_PREDICTION_START_DATE = 'UPDATE_PREDICTION_START_DATE';
export const UPDATE_PREDICTION_END_DATE = 'UPDATE_PREDICTION_END_DATE';

export const UPDATE_COUNTRY_FEATURE_COLUMNS = 'UPDATE_COUNTRY_FEATURE_COLUMNS';
export const UPDATE_FEATURE_COUNTRIES = 'UPDATE_FEATURE_COUNTRIES';
export const UPDATE_FEATURE_START_DATE = 'UPDATE_FEATURE_START_DATE';
export const UPDATE_FEATURE_END_DATE = 'UPDATE_FEATURE_END_DATE';

export const SET_SHOW_SPINNER = 'SET_SHOW_SPINNER';



