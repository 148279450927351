import React, {useEffect} from "react";
import LeafletMap from "../components/map/LeafletMap";
import SidePanel from "../components/sidepanel/SidePanel";
import ChartContainer from "../components/chart-container/chartContainer";
import { useMapDispatch, useMapStore} from "../state/context";
import * as viewsData from "../backend/data";
import * as actions from "../state/actions";
import * as mapUtils from '../utils/mapUtils';

import ErrorPopUp from "../components/error-popup/error-popup";
import Spinner from "../components/spinner/spinner";
import {generateAvailableRuns} from "../utils/dataUtils";
import ViewsLogo from "../components/views-logo/viewsLogo";
import ModelDescriptor from "../components/model-descriptor/modelDescriptor";
import MonthSlider from "../components/month-slider/MonthSlider";
import BackToCountryButton from "../components/cm-button/back-to-country-button/backToCountryButton";

const StartPage = function() {

    const debug = true;

    const store = useMapStore();
    const dispatch = useMapDispatch();


    // Load initial data to be used by app
    useEffect(() => {
        const setup = async () => {
            // make dictionary of countries with geojson data
            const countryGeo = await viewsData.getCountryDict();        // countries.geojson

            // make dictionary of propgrids with geojson data ()  (type, properties, geometry)
            // todo: segment up into country data and fetch from backend with PG-predictions?
            const africanCountryGeo = await viewsData.getGeoJsonMap("africa");              // pg_africa.geojson

            // countries and areas.. should be filtered out by data from backend in the future
            // get dictionary of countries, indexed by country names and id for faster lookups
            const countryDicts =  await viewsData.getCountryDataDict();  // Dictionary { id:{id,name,lat,long},... };

            // Get runs from backend
            const runs = await viewsData.getRuns(dispatch);

            if(runs){

                // setup data and define state

                const runIDs = runs.map( run => run.runId);
                let availableRuns = generateAvailableRuns(runs);

                // Setup default runIds for the UI
                const selectedRunID = runIDs[runIDs.length-1];
                const selectedChangeMapRun1 = runIDs[runIDs.length-1];

                const typesOfViolence = [
                    {value: "sb", label: "State-based conflict"},
                    {value: "os", label: "One-sided violence"},
                    {value: "ns", label: "Non-state conflict"}
                ];

                // Setup map data
                let geoJsonData = mapUtils.createFeatureCollection(Object.values(countryGeo));

                const initialMapSettings = {
                    mapCenterPosition: [0.105,28], // africa
                    zoom: 4,
                    opacity: 0,
                    showOpacitySlider: false,
                    keyMAP: 22
                }

                let changeMapSettings = {
                    runID1: selectedChangeMapRun1,
                    runID2: selectedChangeMapRun1,
                    tov: undefined,
                    model: undefined,
                    area: [],
                    availableModels: []
                }

                const latest_run = availableRuns[selectedRunID];
                const currentMonth = latest_run.start_date;
                const initialChartSettings = {
                    startMonthID: currentMonth,
                    predictionMonthID: currentMonth,
                    context: 'cm'
                }

                const initialSliderSettings = {
                    steps: 0,
                    showMonthSlider: false
                }


                // set the initial values for app
                const result = {
                    runIDs: runIDs,
                    selectedRunID: selectedRunID,
                    availableRuns: availableRuns,
                    typesOfViolence: typesOfViolence,
                    selectableModels: [],
                    selectedModel: {value:undefined, label:undefined},
                    selectablePGMModels: [],
                    selectedPGMModel: "",
                    areas: countryDicts.nameDict,
                    areaDict:countryDicts.idDict,
                    selectedArea: "",
                    clickedAreaID: "",
                    countryGeo: countryGeo,
                    gridGeo: africanCountryGeo,
                    pgmDataStore: {},
                    geoJsonData: geoJsonData,
                    map: geoJsonData,
                    mapSettings: initialMapSettings,
                    chartSettings: initialChartSettings,
                    sliderSettings: initialSliderSettings,
                    isCountryMode: false,
                    showModelDescriptor: false,
                    opacity: true,
                    showSpinner: false,
                    isPredictionsMap: true,
                    predictionsMapMode: 'cm',
                    isChangeMap: false,
                    changeMapSettings: changeMapSettings

                }

                dispatch(actions.setInitialStore(result));
            }

        };

        setup(); // add functionality for handling network errors
    }, [debug, dispatch]);

    const hasMapData = store !== undefined && store.geoJsonData !== undefined;

    let showSpinner = !hasMapData || store.showSpinner

    let showSlider = false;
    if(!store.isChangeMap){
        showSlider= store.selectedModel?.value && store.sliderSettings.showMonthSlider;
    }
    else{
        showSlider = store.sliderSettings.showMonthSlider;
    }

    return (
        <div id={"rootDiv"}>

                <div className={"uuContainer"}>
                    {hasMapData &&
                        <div className={"box map"}>
                            <LeafletMap/>
                        </div>
                    }
                    {showSpinner &&
                        <Spinner/>
                    }
                    {hasMapData &&
                        <SidePanel/>
                    }
                </div>
            <ViewsLogo/>

            {store.showChartContainer &&
                <ChartContainer isCountryMode={store.isCountryMode}/>
            }

            {store.selectedModel?.value &&
                <ModelDescriptor/>
            }

            {showSlider &&
                <MonthSlider></MonthSlider>
            }

            {store.predictionsMapMode === "pgm" &&
                <BackToCountryButton></BackToCountryButton>
            }

            <ErrorPopUp/>

        </div>
    )
};

export default StartPage;

