import React from "react";
import { MapProvider} from "./state/context";
import StartPage from "./pages/startPage";

const App = function() {

  return (
    <MapProvider>
        <StartPage/>
    </MapProvider>
  );
}

export default App;

