


// get stored PGM data for the clicked country from loaded data in memory
import * as actions from "../state/actions";
import * as viewsData from "../backend/data";
import * as mapUtils from "./mapUtils";


export const getPgmData = (store, countryIsoCode) => {
    if (store.selectedRunID in store.pgmDataStore && countryIsoCode in store.pgmDataStore[store.selectedRunID]){
        return store.pgmDataStore[store.selectedRunID][countryIsoCode]
    }
    return false;
}


export function createPGMGeoJson(countryPgmData, store){

    // should get for all loaded areas
    let pgIds = Object.keys(countryPgmData.forecasts[store.selectedTypeOfViolence.value]);

    let featureArr = [];
    pgIds.forEach( pgId => {
        featureArr.push(store.gridGeo[pgId]);
    })

    return mapUtils.createFeatureCollection(featureArr)

}

export const updatePGMDataStore = async (dispatch,store, countryIsoCode) => {

    let countryPgmData = getPgmData(store, countryIsoCode);

    // if pgm data not in store => fetch it from backend and add it
    if(!countryPgmData) {
        dispatch(actions.setSpinner(true))
        countryPgmData = await viewsData.getCountryPGMData(store.selectedRunID, countryIsoCode)
        if(store.selectedRunID in store.pgmDataStore){
            store.pgmDataStore[store.selectedRunID][countryIsoCode] = countryPgmData;
        }
        else{
            store.pgmDataStore[store.selectedRunID] = {};
            store.pgmDataStore[store.selectedRunID][countryIsoCode] = countryPgmData;
        }
    }

    // if still no pgmData => cancel and popup error message
    if(!countryPgmData){
        let errorObject = {
            heading: "Data missing",
            message: `Sorry, data for ${countryIsoCode} couldn't be fetched. Please try again later.`
        }
        dispatch(actions.showError(errorObject))
    }

    return countryPgmData;

}