import React from "react";
import 'react-tabs/style/react-tabs.css';
import "react-toggle/style.css"

import ModelSelector from '../selectors/model-selector/modelSelector';
import TOVSelector from '../selectors/tov-selector/tovSelector';
import ForecastSelector from '../selectors/forecast-selector/forecastSelector';

import * as actions from "../../state/actions";
import {useMapDispatch, useMapStore} from "../../state/context";
import * as mapUtils from "../../utils/mapUtils";
import * as viewsData from "../../backend/data";
import {generateRunObject} from "../../utils/dataUtils";

const ChangeMapTab = () => {

    const store = useMapStore();
    const dispatch = useMapDispatch();
    const hasTOV = store.changeMapSettings.tov !== undefined;
    let modelDescriptions = {};
    if(!store.showSpinner){
        modelDescriptions = store.availableRuns[store.selectedRunID].codeBook[store.predictionsMapMode]
    }

    const filterModels = tov => {
        return store.availableRuns[store.changeMapSettings.runID1].models[tov];
    }

    const setTypeOfViolence = tov => {

        const selectableModels = filterModels(tov.value);
        let changeMapSettings = store.changeMapSettings;
        changeMapSettings.tov = tov;
        changeMapSettings.availableModels = selectableModels;
        dispatch(actions.updateChangeMapSettings(changeMapSettings, store.availableRuns, store.sliderSettings));

    }

    const setModel = model => {
        let changeMapSettings = store.changeMapSettings;
        changeMapSettings.model = model;
        dispatch(actions.updateChangeMapSettings(changeMapSettings, store.availableRuns, store.sliderSettings));
    }


    const updateRunID = async (store, runId, changeMapSettings) => {
        if(store.availableRuns[runId].cmData === undefined){
            dispatch(actions.setSpinner(true))
            const runData = await viewsData.getRunData(runId);
            store.availableRuns[runId] = generateRunObject(runData);
            dispatch(actions.updateChangeMapSettings(changeMapSettings, store.availableRuns, store.sliderSettings));
        }
        else{
            dispatch(actions.updateChangeMapSettings(changeMapSettings, store.availableRuns, store.sliderSettings));
        }
    }

    const fetchAndUpdateRunData = async (availableRuns, runId) => {
        if(availableRuns[runId].cmData === undefined){
            const runData = await viewsData.getRunData(runId);
            availableRuns[runId] = generateRunObject(runData);
        }
        return availableRuns;
    }

    const updateRunIDS = async (store, changeMapSettings) => {
        if(store.availableRuns[changeMapSettings.runID1].cmData === undefined || store.availableRuns[changeMapSettings.runID2].cmData === undefined ){
            dispatch(actions.setSpinner(true))
        }

        store.availableRuns = await fetchAndUpdateRunData(store.availableRuns, changeMapSettings.runID1);
        store.availableRuns = await fetchAndUpdateRunData(store.availableRuns, changeMapSettings.runID2);

        store.sliderSettings.steps = 0;

        dispatch(actions.updateChangeMapSettings(changeMapSettings, store.availableRuns, store.sliderSettings));
    }

    const setRunID1 = async runId => {
        let changeMapSettings = store.changeMapSettings;
        if(store.changeMapSettings.runID2 > runId || store.changeMapSettings.runID2 === runId){
            let nextID = getNextComparableRunID(runId);
            changeMapSettings.runID2 = nextID;
        }
        changeMapSettings.runID1 = runId;
        await updateRunIDS(store, changeMapSettings);
    }



    const setRunID2 = async runId => {
        let changeMapSettings = store.changeMapSettings;
        changeMapSettings.runID2 = runId;
        await updateRunID(store, runId, changeMapSettings);
    }

    const submit = async () => {
        let mSettings = store.mapSettings;
        mSettings.opacity= 0.5;
        mSettings.showOpacitySlider = true;
        let geoData = mapUtils.createFeatureCollection(Object.values(store.countryGeo));
        let sliderSettings = store.sliderSettings;
        sliderSettings.showMonthSlider = true;
        const errorObject = undefined;

        let chartSettings = {...store.chartSettings};
        //chartSettings.
        dispatch(actions.updateMap(store.availableRuns, geoData, mSettings, store.isCountryMode, store.clickedCountry, store.predictionsMapMode, store.pgmDataStore, store.chartSettings, errorObject, store.showChartContainer, sliderSettings, store.selectedModel));
    }

    const getNextComparableRunID = (currentRunId) => {
        let id;
        store.runIDs.some(function(runId) {
            id = runId;
            return runId < currentRunId;
        });
        return id;
    }

    // Disable last runID of first selector as it isnt comparable to any previous run
    const disabledIDs1 = [];
    disabledIDs1.push(store.runIDs[store.runIDs.length - 1]);
    const runIds1 = [...store.runIDs];
    runIds1.pop();


    const disabledIDs2 = [];
    const runIds2 = []
    store.runIDs.forEach(runId =>{
        if(runId > store.changeMapSettings.runID1 || runId === store.changeMapSettings.runID1){
            disabledIDs2.push(runId)
        }
        else{
            runIds2.push(runId)
        }
    })


    return (
        <React.Fragment>

            <ForecastSelector
                headLine="Data release (main):"
                runIds ={runIds1}
                disabledIDs={disabledIDs1}
                selectedRunID={store.changeMapSettings.runID1}
                setRunID={setRunID1}/>

            <ForecastSelector
                headLine="Compared release:"
                runIds ={runIds2}
                disabledIDs={disabledIDs2}
                selectedRunID={store.changeMapSettings.runID2}
                setRunID={setRunID2}/>

            <TOVSelector
                selectedTypeOfViolence={store.changeMapSettings.tov}
                typesOfViolence={store.typesOfViolence}
                setTypeOfViolence={setTypeOfViolence}
                headLine="Type of violence:"
            />

            <ModelSelector
                models={store.changeMapSettings.availableModels}
                descriptions={modelDescriptions}
                isDisabled={!hasTOV}
                selectedModel={store.changeMapSettings.model}
                setModel={setModel}
                headLine="Prediction model:"
            />

            <div className={'menuItem'}>
                <div className={'selector-description'}></div>
                <div className={'selector-container'}>
                    <button className={"submit"} onClick={submit}>Generate map</button>
                </div>
            </div>


        </React.Fragment>
    )

}

export default ChangeMapTab;