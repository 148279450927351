import React from "react";
import '../selectors.css';
import {getModelDescription} from "../../../utils/modelUtils";
import {Menu, MenuButton, MenuDivider, MenuItem} from "@szhsin/react-menu";
import {ArrowRightIcon, ChevronDownIcon} from "@primer/octicons-react";


const ModelSelector = props => {

    const modelOptions = []
    let mainModel = {value:"", label:""};
    let selectedModel;

    if(props.models){
        props.models.forEach(techmodel => {
            let description = getModelDescription(techmodel, props.descriptions);
            if(techmodel === "main") mainModel = {value:techmodel, label:description}
            if(props.selectedModel && techmodel === props.selectedModel.value) selectedModel = {value:techmodel, label:description}
            modelOptions.push({value:techmodel, label:description})
        })
    }


    const handleFileClick = e => {
        let selectOption = e.value;
        let model = modelOptions.find( opt => opt.value === selectOption.value)
        props.setModel(model);
        e.stopPropagation = true;
    }

    function compare( a, b ) {
        if ( a.label < b.label ){
            return -1;
        }
        if ( a.label > b.label ){
            return 1;
        }
        return 0;
    }

    let orderedIds = modelOptions.sort( compare );

    const header = props.selectedModel ? selectedModel.label : "Select model";
    let mainSelection = props.isDisabled ? "Select type of violence first" : mainModel.label

    const getDisabled = (option) => {
        return props.isDisabled || option.value === props.selectedModel?.value;
    }

    return (
        <div className={'menuItem'}>
            <div className={'selector-description'}>
                {props.headLine} <ArrowRightIcon size={12}></ArrowRightIcon>
            </div>
            <div className={'selector-container'}>
                <Menu menuButton={<MenuButton className={'selector_button'}><div className={'selectorTextContainer'}>{header}</div><div className={'selectorIcons'}> | <ChevronDownIcon color="#626262" size={20} /></div></MenuButton>}>
                    <MenuItem value={mainModel} disabled={getDisabled(mainModel)} onClick={handleFileClick}>{mainSelection}</MenuItem>
                    <MenuDivider />
                    {orderedIds.map((option, i) => <MenuItem value={option} disabled={getDisabled(option)} onClick={handleFileClick} key={i} >{option.label} </MenuItem>)}
                </Menu>

            </div>
        </div>
    );

}

export default ModelSelector;