import React from "react";
// import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';

import { Map, TileLayer, GeoJSON, ZoomControl } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './leaflet-map.css';

import {getFeatureStyle, setupFeatureActions} from "./leafletUtils";

import RainbowGradient from '../gradients/rainbow/rainbow'
import SeismicGradient from '../gradients/seismic/seismic'
import {useMapDispatch, useMapStore} from "../../state/context";
import OpacitySlider from "../opacity-slider/opacitySlider";

const LeafletMap = props => {

    const store = useMapStore();
    const dispatch = useMapDispatch();

    let geoJsonData = store.geoJsonData;
    const center = store.mapSettings.mapCenterPosition;

    // Tile to be used by map. Be aware that some maps aren't free
    //let freetile = "http://{s}.tile.opencyclemap.org/cycle/{z}/{x}/{y}.png"
    let tile = "https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"

    return (
        <div className={'leafLetMap'}>
            <div className={'mapContainer'}>
                <Map
                    preferCanvas={true}
                    center={center}
                    key={store.mapSettings.keyMAP}
                    zoom={store.mapSettings.zoom}
                    minZoom={3}
                    maxZoom={18}
                    zoomControl={false}>
                    <ZoomControl position="bottomleft" zoomInText="+" zoomOutText="-" />
                    <TileLayer
                        url={tile}
                        attribution={"&copy; <a href=\"https://stadiamaps.com/\">Stadia Maps</a>, &copy; <a href=\"https://openmaptiles.org/\">OpenMapTiles</a> &copy; <a href=\"http://openstreetmap.org\">OpenStreetMap</a> contributors"}
                    />
                    {geoJsonData &&
                        <GeoJSON
                            key={store.key}
                            data={geoJsonData}
                            style={feature => getFeatureStyle(feature, store)}
                            onEachFeature={(feature, layer) => setupFeatureActions(feature, layer, store, dispatch)} >
                        </GeoJSON>
                    }

                </Map>
            </div>

           { store.isPredictionsMap &&
            <RainbowGradient/>
           }
           { !store.isPredictionsMap &&
            <SeismicGradient/>
           }

           <OpacitySlider></OpacitySlider>
        </div>
    );
}

export default LeafletMap;