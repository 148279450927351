import React from "react";
import {useMapDispatch, useMapStore} from "../../../state/context";
import "./back-to-country-button.css"
import * as mapUtils from "../../../utils/mapUtils";
import * as actions from "../../../state/actions";
import * as viewsData from "../../../backend/data";
import {getSelectableModels} from "../../../utils/cmUtils";

const BackToCountryButton =  props => {

    const store = useMapStore();
    const dispatch = useMapDispatch();

    const setCMMode  = async () => {
        //get current country!
        // let predictionsMapMode = 'cm';
        if(store.selectedModel?.value !== ""){

            const countryGeo = await viewsData.getCountryDict();        // countries.geojson
            let geoJsonData = mapUtils.createFeatureCollection(Object.values(countryGeo));

            let mapSettings = store.mapSettings;
            mapSettings.showOpacitySlider = true;
            if(store.selectedModel?.value!=="main") {
                mapSettings.opacity = 0;
            }
            mapSettings.zoom = 4;
            mapSettings.mapCenterPosition = [0.105,28]; // africa
            mapSettings.keyMAP = 22;

            const isCountryMode = true;
            const predictionsMapMode = "cm";


            let selectableModels = getSelectableModels(store);

            let lmodel = store.selectedModel?.value.toLowerCase();
            let hasModel = selectableModels.includes(lmodel)
            if(!hasModel){
                store.selectedModel = undefined;
            }

            let startDate = store.availableRuns[store.selectedRunID].start_date;

            let chartSettings = store.chartSettings;
            chartSettings.context = "pgm";
            chartSettings.predictionMonthID = startDate;
            chartSettings.startMonthID = startDate;

            let sliderSettings = store.sliderSettings;
            sliderSettings.steps = 0;
            sliderSettings.showMonthSlider = false;
            if(store.selectedModel?.value === "main"){
                sliderSettings.showMonthSlider = true;
            }

            const errorObject = undefined;
            dispatch(actions.updateMap(store.availableRuns, geoJsonData, mapSettings, isCountryMode, store.clickedCountry, predictionsMapMode, store.pgmDataStore, chartSettings, errorObject, false, sliderSettings, store.selectedModel));
        }
    }


    return (
        <button className="back-to-country-button" onClick={setCMMode}> &#8592; Change country </button>
    );

}

export default BackToCountryButton;
