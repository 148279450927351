import React from 'react'

// Split dispatch and store into separate contexts
// See https://dev.to/ankitjena/ciao-redux-using-react-hooks-and-context-effectively-398j
// Useful for creating multiple stores for different contexts

export default function makeStore(reducer, initialState) {

    const storeContext = React.createContext();
    const dispatchContext = React.createContext();

    const StoreProvider = ({children}) => {
        const [store, dispatch] = React.useReducer(reducer, initialState)

        return (
            <dispatchContext.Provider value={dispatch}>
                <storeContext.Provider value={store}>
                    {children}
                </storeContext.Provider>
            </dispatchContext.Provider>
        )
    }

    function useStore() {
        return React.useContext(storeContext)
    }

    function useDispatch() {
        return React.useContext(dispatchContext)
    }

    return [StoreProvider, useStore, useDispatch]
}
