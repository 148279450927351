import ReactSlider from "react-slider";
import React from "react";
import './opacity-slider.css';
import "react-toggle/style.css"

import * as actions from "../../state/actions";
import {useMapDispatch, useMapStore} from "../../state/context";

const OpacitySlider = () => {

    const store = useMapStore();
    const dispatch = useMapDispatch();

    const setOpacity = (value, step) =>{
        let settings = store.mapSettings;

        settings.opacity = 1-value/100;
        dispatch(actions.updateMapSettings(settings));
    }

    const value = (1 - store.mapSettings.opacity)*100;

    return (
        store.mapSettings.showOpacitySlider &&

            <div className={"opacityContainer"}>
                Opacity %
                <div>
                    <div className={'minMaxContainer'}>Min</div>
                    <div className={'sliderWrapper'}>
                        <ReactSlider
                            className={"opacitySlider"}
                            trackClassName={"track"}
                            thumbClassName={"thumb"}
                            defaultValue={value}
                            value={value}
                    renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                            onChange={(value, step) => setOpacity(value, step)}
                            min={0}
                            max={100}
                        />
                    </div>
                    <div className={'minMaxContainer'}>Max</div>
                </div>

            </div>

    )
}

export default OpacitySlider;