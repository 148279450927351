import React from "react";
import "./error-popup.css"

import * as actions from "../../state/actions";
import {useMapDispatch, useMapStore} from "../../state/context";
import Closer from "../closer/closer";

const ErrorPopUp = () => {

    const store = useMapStore();
    const dispatch = useMapDispatch();

    function closePanel(){
        dispatch(actions.hideError());
    }

    return (
        <React.Fragment>
            {store.showError &&
            <div className={'popupContainer'}>
                <div className={'wrapper'}>
                    <Closer onClose={(e) => {
                        closePanel(e)
                    }}>x
                    </Closer>
                    <div className={'messageContainer'}>
                        <div className={'error-heading'}>{store.error.heading}</div>
                        <div className={'description'} dangerouslySetInnerHTML={{__html: store.error.message}}></div>
                    </div>
                </div>
            </div>
            }
        </React.Fragment>
    );

}

export default ErrorPopUp;