import * as types from "./action_types";
import hash from 'object-hash';

export const reducer = (state, action) => {

    switch (action.type) {
        case types.UPDATE_MAP:
            return Object.assign({}, state, {
                availableRuns: action.availableRuns,
                geoJsonData: action.geoJsonData,
                mapSettings: action.mapSettings,
                isCountryMode: action.isCountryMode,
                clickedCountry: action.clickedCountry,
                predictionsMapMode: action.predictionsMapMode,
                pgmDataStore: action.pgmDataStore,
                chartSettings: action.chartSettings,
                showSpinner: false,
                error: action.errorObject,
                showError: !!action.errorObject,
                showChartContainer: action.showChartContainer,
                sliderSettings: action.sliderSettings,
                selectedModel: action.selectedModel,
                key: hash(new Date())
            })


        case types.SET_INITIAL_STORE:
            return Object.assign({}, state, {
                runIDs: action.store.runIDs,
                availableRuns: action.store.availableRuns,
                selectedRunID: action.store.selectedRunID,
                changeMapSettings: action.store.changeMapSettings,
                typesOfViolence: action.store.typesOfViolence,
                selectedTypeOfViolence: action.store.selectedTypeOfViolence,
                selectableModels: action.store.selectableModels,
                selectedModel: action.store.selectedModel,
                selectedArea: action.store.selectedArea,
                areas: action.store.areas,
                areaDict: action.store.areaDict,
                countryGeo: action.store.countryGeo,
                gridGeo: action.store.gridGeo,
                pgmDataStore: action.store.pgmDataStore,
                geoJsonData: action.store.geoJsonData,
                mapSettings: action.store.mapSettings,
                chartSettings: action.store.chartSettings,
                sliderSettings: action.store.sliderSettings,
                isCountryMode: action.store.isCountryMode,
                showModelDescriptor: action.store.showModelDescriptor,
                clickedCountry: action.store.clickedCountry,
                clickedAreaID: action.store.clickedAreaID,
                isPredictionsMap: action.store.isPredictionsMap,
                predictionsMapMode: action.store.predictionsMapMode,
                isChangeMap: action.store.isChangeMap,
                showSpinner: action.store.showSpinner,
                showChartContainer: false,
                showError: false
            })

        case types.SHOW_ERROR:
            return Object.assign({}, state, {
                error: action.error,
                showError: true,
            })

        case types.HIDE_ERROR:
            return Object.assign({}, state, {
                error: {},
                showError: false,
            })

        case types.UPDATE_SET_PREDICTION_MAP_MODE:
            return Object.assign({}, state, {
                predictionsMapMode: action.predictionsMapMode,
                isChangeMap: false,
            })

        case types.UPDATE_IS_CHANGE_MAP:
            return Object.assign({}, state, {
                isChangeMap: action.isChangeMap,
                geoJsonData: action.geoJsonData,
                isCountryMode: action.isCountryMode,
                predictionsMapMode: action.predictionsMapMode,
                isPredictionsMap: false,
                sliderSettings: action.sliderSettings,
                showChartContainer: false,
                showError: false,
                key: hash(new Date())
            })

        case types.UPDATE_IS_PREDICTION_MAP:
            return Object.assign({}, state, {
                isPredictionsMap: action.isPredictionsMap,
                isChangeMap: false,
                sliderSettings: action.sliderSettings,
                predictionsMapMode: "cm",
                key: hash(new Date())
            })

        case types.UPDATE_CHANGE_MAP_SETTINGS:
            return Object.assign({}, state, {
                changeMapSettings: action.changeMapSettings,
                availableRuns: action.availableRuns,
                sliderSettings: action.sliderSettings,
                showSpinner: false,
                key: hash(action.changeMapSettings)
            })

        // change selected run
        case types.UPDATE_RUN_ID:
            return Object.assign({}, state, {
                selectedRunID: action.runID,
                chartSettings: action.chartSettings,
                sliderSettings: action.sliderSettings,
                key: hash(new Date())
            })


        case types.UPDATE_RUN_DATA:
            return Object.assign({}, state, {
                selectedRunID:action.runId,
                availableRuns: action.availableRuns,
                showSpinner: false,
                chartSettings: action.chartSettings,
                sliderSettings: action.sliderSettings,
                key: hash(new Date())
            })

        case types.UPDATE_TYPE_OF_VIOLENCE:
            return Object.assign({}, state, {
                selectedTypeOfViolence: action.tov,
                selectableModels: action.selectableModels,
                key: hash(new Date())
            })

        case types.UPDATE_PREDICTION_MODEL:
            return Object.assign({}, state, {
                selectedModel: action.predictionModel,
                showModelDescriptor: true,
                key: hash(action.predictionModel),
            })

        case types.UPDATE_SELECTED_AREA:
            return Object.assign({}, state, {
                selectedArea: action.selectedArea
            })

        case types.UPDATE_MAP_SETTINGS:
            return Object.assign({}, state, {
                mapSettings: action.mapSettings
            })

        case types.UPDATE_CHART_SETTINGS:
            return Object.assign({}, state, {
                chartSettings: action.chartSettings
            })

        case types.SET_SHOW_CHART_CONTAINER:
            return Object.assign({}, state, {
                showChartContainer: action.showChartContainer
            })

        case types.UPDATE_CHART_SETTINGS_AND_AREA:
            return Object.assign({}, state, {
                chartSettings: action.chartSettings,
                clickedAreaID: action.pgId,
                isCountryMode: true,
                showChartContainer: true
            })

        case types.UPDATE_MONTH_SLIDER:
            return Object.assign({}, state, {
                sliderSettings: action.sliderSettings,
                key: hash(action.sliderSettings)
            })

        case types.SET_IS_COUNTRY_MODE:
            return Object.assign({}, state, {
                isCountryMode: action.mode,
                mapSettings: action.mapSettings,
                showChartContainer: action.showChartContainer,
                clickedCountry: action.clickedCountry
            })

        case types.SET_SHOW_MODEL_DESCRIPTOR:
            return Object.assign({}, state, {
                showModelDescriptor: action.mode,
                mapSettings: action.mapSettings
            })


        /* _________________________ */



        // Predictions
        case types.UPDATE_COUNTRY_PREDICTION_COLUMNS:
            return Object.assign({}, state, {
                selectedCountryPredictionColumns: action.countryPredictionColumns
            })

        case types.UPDATE_PREDICTION_COUNTRIES:
            return Object.assign({}, state, {
                selectedPredictionCountries: action.predictionCountries
            })


        case types.SET_SHOW_SPINNER:
            return Object.assign({}, state, {
                showSpinner: action.show
            })



        case types.UPDATE_PREDICTION_VERSION:
            return Object.assign({}, state, {
                selectedPredictionVersion: action.predictionVersion
            })

        case types.UPDATE_PREDICTION_START_DATE:
            return Object.assign({}, state, {
                predictionStartDate: action.predictionStartDate
            })

        case types.UPDATE_PREDICTION_END_DATE:
            return Object.assign({}, state, {
                predictionEndDate: action.predictionEndDate
            })

        // Features
        case types.UPDATE_COUNTRY_FEATURE_COLUMNS:
            return Object.assign({}, state, {
                selectedCountryFeatureColumns: action.countryFeatureColumns
            })

        case types.UPDATE_FEATURE_COUNTRIES:
            return Object.assign({}, state, {
                featureCountries: action.featureCountries
            })

        case types.UPDATE_FEATURE_START_DATE:
            return Object.assign({}, state, {
                featureStartDate: action.featureStartDate
            })

        case types.UPDATE_FEATURE_END_DATE:
            return Object.assign({}, state, {
                featureEndDate: action.featureEndDate
            })
        default:
            return state
    }
}


