import React from "react";
import 'react-tabs/style/react-tabs.css';
import "react-toggle/style.css"

import ModelSelector from '../selectors/model-selector/modelSelector';
import TOVSelector from '../selectors/tov-selector/tovSelector';
import ForecastSelector from '../selectors/forecast-selector/forecastSelector';
import * as predictionUtils from '../../utils/predictionUtils';

import * as actions from "../../state/actions";
import {useMapDispatch, useMapStore} from "../../state/context";
import * as mapUtils from "../../utils/mapUtils";
import {verifyAndUpdateRunData} from "../../utils/dataUtils";
import * as pgmUtils from "../../utils/pgmUtils";

const PGMTab = () => {

    const store = useMapStore();
    const dispatch = useMapDispatch();
    const hasTOV = store.selectedTypeOfViolence !== undefined;
    // correct?
    let modelDescriptions = {};
    if(!store.showSpinner){
        modelDescriptions = store.availableRuns[store.selectedRunID].codeBook[store.predictionsMapMode]
    }

    const setRunID = async runId => {
        const clickedCountryID = store.clickedCountry
        const isoCode = store.areaDict[clickedCountryID]?.isoCode;

        if(!store.pgmDataStore[runId]?.[isoCode]){
            store.selectedRunID = runId;
            let countryPgmData = await pgmUtils.updatePGMDataStore(dispatch, store, isoCode)

            const geoJsonData = pgmUtils.createPGMGeoJson(countryPgmData, store);
            const mapSettings = mapUtils.setMapSettings(store, clickedCountryID);
            mapSettings.zoom = 6;
            let predictionsMapMode = 'pgm';
            const isCountryMode = true;

            let chartSettings = store.chartSettings;
            chartSettings.context = "pgm";

            let updatedAvailableRuns = await verifyAndUpdateRunData(store.availableRuns, store.selectedRunID)
            const errorObject = undefined;

            dispatch(actions.updateMap(updatedAvailableRuns, geoJsonData, mapSettings, isCountryMode, clickedCountryID, predictionsMapMode, store.pgmDataStore, chartSettings, errorObject, store.showChartContainer, store.sliderSettings, store.selectedModel));
        }
        else if( !store.availableRuns[store.selectedRunID].cmData || !store.availableRuns[store.selectedRunID].codeBook ){
            let updatedAvailableRuns = await verifyAndUpdateRunData(store.availableRuns, store.selectedRunID)

            let currentRun = store.availableRuns[store.selectedRunID];
            // Update start month.
            // Todo Refactor entire handling of prediction starting month now that it's added to the run object
            store.chartSettings.startMonthID = currentRun.start_date
            store.chartSettings.predictionMonthID = currentRun.start_date

            dispatch(actions.updateRunData(runId, updatedAvailableRuns, store.chartSettings, store.sliderSettings))
        }
        else{

            let newRun = store.availableRuns[runId];
            // Update start month.
            // Todo Refactor entire handling of prediction starting month now that it's added to the run object
            store.chartSettings.startMonthID = newRun.start_date
            store.chartSettings.predictionMonthID = newRun.start_date

            dispatch(actions.updateSelectedRunID(runId, store.chartSettings, store.sliderSettings));
        }
    }

    const filterModels = tov => {
        return store.availableRuns[store.selectedRunID]?.models[tov];
    }

    const setTypeOfViolence = tov => {
        if(tov.value!==store.selectedTypeOfViolence.value){
            const selectableModels = filterModels(tov.value);
            dispatch(actions.updateTypeOfViolence(tov, selectableModels));
        }
    }

    const setModel = model => {
        if(model.value!==store.selectedModel?.value){
            dispatch(actions.updatePredictionModel(model));
        }
    }

    const submit = async () => {
        if(store.selectedModel !== ""){
            store.sliderSettings.showMonthSlider = true;
            dispatch(actions.updateMonthSlider(store.sliderSettings, store.chartSettings))
        }
    }

    const getSelectableModels = store => {
        let predictions = predictionUtils.getActiveCountryPGMPredictions(store);
        return predictions?.models;
    }

    const getSelectedModel = store => {
        let pgmModels = getSelectableModels(store);
        if(store.selectedModel && pgmModels && pgmModels.includes(store.selectedModel?.value)){
            return store.selectedModel;
        }

        return ""; // or whatever it should be when empty
    }


    //const className = "submit";
    const className = store.selectedModel ? "submit":"submit_inactive";
    let selectableModels = getSelectableModels(store);
    let selectedModel = getSelectedModel(store);


    return (

        <React.Fragment>

            <ForecastSelector
                 runIds ={store.runIDs}
                 selectedRunID={store.selectedRunID}
                 setRunID={setRunID}
                 headLine="Forecast version:"/>


            <TOVSelector
                selectedTypeOfViolence={store.selectedTypeOfViolence}
                typesOfViolence={store.typesOfViolence}
                setTypeOfViolence={setTypeOfViolence}
                headLine="Type of violence:"
            />

            <ModelSelector
                models={selectableModels}
                descriptions={modelDescriptions}
                isDisabled={!hasTOV}
                selectedModel={selectedModel}
                setModel={setModel}
                headLine="Prediction model:"
            />

            <div className={'menuItem'}>
                <div className={'selector-description'}></div>
                <div className={'selector-container'}>
                    <button className={className} onClick={submit}>Fetch predictions</button>
                </div>
            </div>

        </React.Fragment>
    )

}

export default PGMTab;