import * as types from './action_types';

/*
 * action creators
 */

export function setInitialStore(store) {
    return { type: types.SET_INITIAL_STORE, store: store }
}


export function showError(error) {
    return { type: types.SHOW_ERROR, error: error}
}

export function hideError() {
    return { type: types.HIDE_ERROR}
}

export function updateMap(availableRuns, geoJsonData, mapSettings, isCountryMode, clickedCountry, predictionsMapMode, pgmDataStore, chartSettings, errorObject, showChartContainer, sliderSettings, selectedModel) {
    return { type: types.UPDATE_MAP, availableRuns: availableRuns, geoJsonData: geoJsonData, mapSettings: mapSettings, isCountryMode:isCountryMode, clickedCountry:clickedCountry, predictionsMapMode: predictionsMapMode, pgmDataStore: pgmDataStore, chartSettings: chartSettings, errorObject:errorObject, showChartContainer:showChartContainer, sliderSettings: sliderSettings, selectedModel: selectedModel}
}

export function updateIsPredictionMap(isPredictionsMap, sliderSettings) {
    return { type: types.UPDATE_IS_PREDICTION_MAP, isPredictionsMap: isPredictionsMap, sliderSettings: sliderSettings }
}

export function updateSetPredictionMapMode(mode) {
    return { type: types.UPDATE_SET_PREDICTION_MAP_MODE, predictionsMapMode: mode }
}

export function updateIsChangeMap(isChangeMap, sliderSettings, geoJsonData, isCountryMode, predictionsMapMode) {
    return { type: types.UPDATE_IS_CHANGE_MAP, isChangeMap: isChangeMap, sliderSettings: sliderSettings, geoJsonData: geoJsonData, isCountryMode: isCountryMode, predictionsMapMode: predictionsMapMode }
}

export function updateChangeMapSettings(changeMapSettings, availableRuns, sliderSettings) {
    return { type: types.UPDATE_CHANGE_MAP_SETTINGS, changeMapSettings: changeMapSettings, availableRuns: availableRuns, sliderSettings: sliderSettings }
}

export function updateCountryPredictionColumns(columns) {
    return { type: types.UPDATE_COUNTRY_PREDICTION_COLUMNS, countryPredictionColumns: columns }
}


export function updatePredictionModel(model) {
    return { type: types.UPDATE_PREDICTION_MODEL, predictionModel: model }
}

export function updateSelectedRunID(runID, chartSettings, sliderSettings) {
    return { type: types.UPDATE_RUN_ID, runID: runID, chartSettings: chartSettings, sliderSettings: sliderSettings }
}

export function updateRunData(runId, availableRuns, chartSettings, sliderSettings) {
    return { type: types.UPDATE_RUN_DATA, runId: runId, availableRuns: availableRuns, chartSettings: chartSettings, sliderSettings: sliderSettings }
}


export function updateTypeOfViolence(tov, models) {
    return { type: types.UPDATE_TYPE_OF_VIOLENCE, tov: tov, selectableModels: models }
}

export function updateSelectedArea(area) {
    return { type: types.UPDATE_SELECTED_AREA, area: area }
}

export function updateMapSettings(mapSettings) {
    return { type: types.UPDATE_MAP_SETTINGS, mapSettings: mapSettings }
}

export function updateMonthSlider(sliderSettings) {
    return { type: types.UPDATE_MONTH_SLIDER, sliderSettings: sliderSettings}
}

export function updateChartSettings(chartSettings) {
    return { type: types.UPDATE_CHART_SETTINGS, chartSettings: chartSettings}
}

export function updateChartSettingsAndArea(chartSettings, pgId) {
    return { type: types.UPDATE_CHART_SETTINGS_AND_AREA, chartSettings: chartSettings, pgId: pgId}
}

export function setIsCountryMode(mode, mapSettings, showChartContainer, clickedCountry) {
    return { type: types.SET_IS_COUNTRY_MODE, mode: mode, mapSettings: mapSettings, showChartContainer: showChartContainer, clickedCountry: clickedCountry}
}

export function setShowModelDescriptor(mode, mapSettings) {
    console.log("Setting is country mode to : " + mode)
    return { type: types.SET_SHOW_MODEL_DESCRIPTOR, mode: mode, mapSettings: mapSettings }
}

export function setShowChartContainer(mode) {
    return { type: types.SET_SHOW_CHART_CONTAINER, showChartContainer: mode,  }
}



export function updatePredictionVersion(version) {
    return { type: types.UPDATE_PREDICTION_VERSION, predictionVersion: version }
}

export function updatePredictionStartDate(date) {
    return { type: types.UPDATE_PREDICTION_START_DATE, predictionStartDate: date }
}
export function updatePredictionEndDate(date) {
    return { type: types.UPDATE_PREDICTION_END_DATE, predictionEndDate: date }
}

export function updateCountryFeatureColumns(columns) {
    return { type: types.UPDATE_COUNTRY_FEATURE_COLUMNS, countryFeatureColumns: columns }
}
export function updateFeatureCountries(countries) {
    return { type: types.UPDATE_FEATURE_COUNTRIES, featureCountries: countries }
}
export function updateFeatureStartDate(date) {
    return { type: types.UPDATE_FEATURE_START_DATE, featureStartDate: date }
}
export function updateFeatureEndDate(date) {
    return { type: types.UPDATE_FEATURE_END_DATE, featureEndDate: date }
}

export function setSpinner(show) {
    return { type: types.SET_SHOW_SPINNER, show: show }
}




