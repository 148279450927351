import React from "react";
import {useMapDispatch, useMapStore} from "../../state/context";
import "./cmButton.css"
import * as mapUtils from "../../utils/mapUtils";
import * as actions from "../../state/actions";
import * as viewsData from "../../backend/data";
import * as pgmUtils from "../../utils/pgmUtils";
import {verifyAndUpdateRunData} from "../../utils/dataUtils";
import {getSelectedModel, getSelectableModels} from "../../utils/cmUtils";

const CMButton =  props => {

    const store = useMapStore();
    const dispatch = useMapDispatch();

    const isCountryLevel = store.predictionsMapMode === "cm";

    const setPGMMode  = async () => {

        if(store.selectedTypeOfViolence && store.selectedModel?.value) {

            const isCountryMode = true;

            const clickedCountryID = store.clickedCountry;
            const mapSettings = mapUtils.setMapSettings(store, clickedCountryID);
            mapSettings.zoom = 6;
            let countryIsoCode = store.areaDict[clickedCountryID]?.isoCode;

            let countryPgmData = await pgmUtils.updatePGMDataStore(dispatch, store, countryIsoCode)

            const geoJsonData = pgmUtils.createPGMGeoJson(countryPgmData, store);

            let updatedAvailableRuns = await verifyAndUpdateRunData(store.availableRuns, store.selectedRunID)

            let lmodel = store.selectedModel?.value.toLowerCase();
            let hasModel = countryPgmData.models.includes(lmodel)
            let errorObj = undefined;
            if (!hasModel) {
                store.selectedModel = undefined;
                errorObj = {
                    heading: "Choose prediction settings",
                    message: `<p>Please specify your forecast selection in the menu above to fetch sub-national data. <br/><br/>To change country, please return to the country view and make a new selection.`
                }
            }

            let predictionsMapMode = "pgm";

            let startDate = store.availableRuns[store.selectedRunID].start_date;

            let chartSettings = store.chartSettings;
            chartSettings.context = "pgm";
            chartSettings.predictionMonthID = startDate;
            chartSettings.startMonthID = startDate;

            let sliderSettings = store.sliderSettings;
            sliderSettings.steps = 0;
            sliderSettings.showMonthSlider = false;
            if(store.selectedModel?.value==="main"){
                sliderSettings.showMonthSlider = true;
            }

            dispatch(actions.updateMap(updatedAvailableRuns, geoJsonData, mapSettings, isCountryMode, clickedCountryID, predictionsMapMode, store.pgmDataStore, chartSettings, errorObj, false, sliderSettings, store.selectedModel));

        }
    }

    const setCMMode  = async () => {
        //get current country!
        // let predictionsMapMode = 'cm';
        if(store.selectedModel?.value !== ""){

            const countryGeo = await viewsData.getCountryDict();        // countries.geojson
            let geoJsonData = mapUtils.createFeatureCollection(Object.values(countryGeo));

            let mapSettings = store.mapSettings;
            mapSettings.showOpacitySlider = true;
            if(store.selectedModel?.value!=="main") {
                mapSettings.opacity = 0;
            }
            mapSettings.zoom = 4;
            mapSettings.mapCenterPosition = [0.105,28]; // africa
            mapSettings.keyMAP = 22;

            const isCountryMode = true;
            const predictionsMapMode = "cm";


            let selectableModels = getSelectableModels(store);

            let lmodel = store.selectedModel?.value.toLowerCase();
            let hasModel = selectableModels.includes(lmodel)
            if(!hasModel){
                store.selectedModel = undefined;
            }

            let startDate = store.availableRuns[store.selectedRunID].start_date;

            let chartSettings = store.chartSettings;
            chartSettings.context = "pgm";
            chartSettings.predictionMonthID = startDate;
            chartSettings.startMonthID = startDate;

            let sliderSettings = store.sliderSettings;
            sliderSettings.steps = 0;
            sliderSettings.showMonthSlider = false;
            if(store.selectedModel?.value==="main"){
                sliderSettings.showMonthSlider = true;
            }

            const errorObject = undefined;
            dispatch(actions.updateMap(store.availableRuns, geoJsonData, mapSettings, isCountryMode, store.clickedCountry, predictionsMapMode, store.pgmDataStore, chartSettings, errorObject, false, sliderSettings, store.selectedModel));
        }
    }

    const setMapMode = async () => {
        if(isCountryLevel){
            await setPGMMode();
        }
        else {
            await setCMMode();
        }
    }

    let buttonText = isCountryLevel ? `Go to sub-national forecasts` : "Change country";

    return (
        <div className={"button-container"}>
            { isCountryLevel &&
                <button className="cm-button" onClick={setMapMode}> &#8594; {buttonText} </button>
            }
            { !isCountryLevel &&
                <button className="cm-button" onClick={setMapMode}> &#8592; {buttonText} </button>
            }
        </div>
    );

}

export default CMButton;
