

var dateobj = new Date();
function pad(n) {return n < 10 ? "0"+n : n;}
var result = pad(dateobj.getDate())+"/"+pad(dateobj.getMonth()+1)+"/"+dateobj.getFullYear();

export const getMonthFromMonthID = monthID => {

    const startMonth = new Date("1979/12/01");
    let monthIntVal = parseInt(monthID, 10)

    startMonth.setMonth(startMonth.getMonth() + monthIntVal);

    let month = startMonth.toLocaleDateString("en-US", {month: 'short'})
    let year = startMonth.toLocaleDateString("en-US", {year: 'numeric'})
    let output = `${month}/${year}`

    return output;
}

export const getCurrentMonthID = () => {

    const from = new Date("December, 1979");
    const to = new Date();

    return to.getMonth() - from.getMonth() +
        (12 * (to.getFullYear() - from.getFullYear()))

}

export const getPredictionMonthID = (store, runId) =>{
    return store.availableRuns[runId].start_date + store.sliderSettings.steps;
}