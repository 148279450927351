import * as actions from "../state/actions";

const host = process.env.REACT_APP_API_URL;

export async function postData(queryObject, url){
    const queryCFG = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(queryObject)
    };

    const response = await fetch(url, queryCFG);
    return await response.json();
}

export async function getCountryPredictions(queryObject){
    const url = `${host}/api/predictions/countries/`;
    return await postData(queryObject, url);
}

export async function getGridPredictions(queryObject){
    const url = `${host}/api/predictions/areas/`;
    return await postData(queryObject, url);
}

export async function getCountryFeatures(queryObject){
    const url = `${host}/api/features/countries/`;
    return await postData(queryObject, url);
}

export async function getGridFeatures(queryObject){
    const url = `${host}/api/features/areas/`;
    return await postData(queryObject, url);
}

// Meta data a.k.a column names
export async function getCountryFeatureColumns(){
    const url=`${host}/api/columns/features/countries/`;
    const response = await fetch(url);
    return await response.json();
}

export async function getAreaFeatureColumns(){
    const url=`${host}/api/columns/features/areas/`;
    const response = await fetch(url);
    return await response.json();
}

export async function getCountryPredictionColumns(){
    const url=`${host}/api/columns/predictions/countries/`;
    const response = await fetch(url);
    return await response.json();
}

export async function getCountryPGMData(runId, countryIso){
    const url=`${host}/api/pgm/country?runId=${runId}&countryIso=${countryIso}`;
    const response = await fetch(url);
    return await response.json();
}

export async function getRuns(dispatch){
    const url=`${host}/api/runs`;

    let response = await fetch(url)
        .catch((error) => {
            // Your error is here!
            console.log(error)
            const errorObj = {
                heading: "Network down",
                message: "Couldn't contact backend server ("+url+"). Try again later."
            }
            dispatch(actions.showError(errorObj));
        });

    if(response){
        return await response.json();
    }


    /*
    await fetch(url)
        .then(async response => {
            return await response.json();
        })
        .catch((error) => {
            // Your error is here!
            console.log(error)
            const errorObj = {
                heading: "Network down",
                message: "Couldn't contact backend server. Try again later."
            }
            dispatch(actions.showError(errorObj));
        });
*/
}

export async function getRunData(runId){
    const url=`${host}/api/runs?run=${runId}`;
    const response = await fetch(url);
    return await response.json();
}

export async function getMergedData(){
    const url=`${host}/api/merger`;
    const response = await fetch(url);
    return await response.json();
}

