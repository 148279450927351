import React from "react";
import '../selectors.css';

import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import {ChevronDownIcon, ArrowRightIcon} from '@primer/octicons-react'


const cleanupLabel = (originalText) => {
    return originalText.replace("r_","");
}
const ForecastSelector = props => {

    const handleFileClick = e => {
        props.setRunID(e.value);
        e.stopPropagation = true;
    }

    const getDisabled = (runId) => {
        return props.isDisabled || runId === props.selectedRunID || props.disabledIDs?.includes(runId);
    }

    let orderedIds = props.runIds.sort().reverse();
    const header = props.selectedRunID ? cleanupLabel(props.selectedRunID) : "Select data release";

    return (
        <div className={'menuItem'}>
            <div className={'selector-description'}>
                {props.headLine} <ArrowRightIcon size={12}></ArrowRightIcon>
            </div>
            <div className={'selector-container'}>
                <Menu menuButton={<MenuButton className={'selector_button'}><div className={'selectorTextContainer'}>{header}</div> | <ChevronDownIcon color="#626262" size={20} /></MenuButton>}>
                    {orderedIds.map((runId, i) =>
                        <MenuItem value={runId} onClick={handleFileClick} disabled={getDisabled(runId)}  key={i}>{cleanupLabel(runId)} </MenuItem>)
                    }
                </Menu>

            </div>
        </div>
    );

}

export default ForecastSelector;
