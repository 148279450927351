import React from "react";
import {useMapDispatch, useMapStore} from "../../state/context"
import './modelDescriptor.css';
import * as actions from "../../state/actions";
import Draggable from 'react-draggable';
import Closer from "../closer/closer";

const ModelDescriptor = props => {

    const store = useMapStore();
    const dispatch = useMapDispatch();

    function closePanel(){
        let mSettings = store.mapSettings;
        mSettings.mapKEY = Math.random();
        dispatch(actions.setShowModelDescriptor(false, mSettings));
    }

    function getData(model){
        let info = "";
        if(store.availableRuns[store.selectedRunID]?.codeBook !== undefined){
            let cmDescriptions = store.availableRuns[store.selectedRunID].codeBook[store.predictionsMapMode]
            if(cmDescriptions.length>0){
                let lmodel = model.toLowerCase();
                info = cmDescriptions.find(element => lmodel.includes(element["id"].toLowerCase()));
            }
        }
        return info;

    }

    const info = store.selectedModel?.value ? getData(store.selectedModel.value):"";

    const showModelDescriptor = info && store.showModelDescriptor;

    return (
        <React.Fragment>
        {showModelDescriptor &&
        <Draggable bounds="#rootDiv" handle="#modelTitle">
                <div className={'modelDescriptionContainer'}>
                    <div id={'modelTitle'} title={"Click to drag and move"}>
                        <span className={"countryHeader"}>{info.name}</span>
                        <Closer onClose={(e)=>{closePanel(e)}}>x</Closer>
                    </div>
                    <span className={'description'}>{info.description}</span>
                </div>
        </Draggable>
        }
        </React.Fragment>
    );


};

export default ModelDescriptor;







